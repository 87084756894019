<template>
    <img v-if="src" :style="mystyle" @click="imgClick" :src="src" :alt="alt" :class="custome_class" :width="width" :height="height" :id="id" @error="errorImg($event)">
</template>

<script>
export default {
    props: ['custome_class', 'id', 'width', 'height', 'src', 'alt', 'mystyle'],
    methods: {
        /**图片加载失败显示自定义默认图片(缺省图)*/
        errorImg(e) {
            // console.log(e.target.src,"法1")
            // console.log(e.srcElement.src,"法2")
            e.srcElement.src = require('@/assets/img/img_fail.png');
            //这一句没用，如果默认图片的路径错了还是会一直闪屏，在方法的前面加个.once只让它执行一次也没用
            e.srcElement.onerror = null; //防止闪图
        },
        imgClick(){
            this.$emit('click')
        }
    }
}
</script>

<style lang="less" scoped>
img{
    user-select: none;
    -webkit-user-select: none;
}
</style>
