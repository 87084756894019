<template>
    <div class="addressInfo">
        <el-tabs style="width: 100% ;display: block !important; margin-left: 20px;" v-model="activeName"
            @tab-click="handleClick">
            <el-tab-pane label="内 部 商 家" name="1"></el-tab-pane>
            <el-tab-pane label="外 部 商 家" name="2"></el-tab-pane>
        </el-tabs>
        <el-form label-width="70px" class="search" style="display: flex !important">
            <el-form-item label="商家名称">
                <el-input class="search-input" placeholder="商家名称" prefix-icon="el-icon-search" v-model="business_name"
                    ref="searchInput1">
                </el-input>
            </el-form-item>
            <el-form-item label="状态" v-if="activeName == 1">
                <el-select v-model="selectedValue" placeholder="请选择状态">
                    <el-option v-for="(item, index) in statusData" :key="index" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> </el-form-item>&nbsp;&nbsp;&nbsp;
            <el-button type="primary" icon="el-icon-search"
                @click="getTableData(1, activeName)">搜索</el-button>&nbsp;&nbsp;&nbsp;
            <el-button type="" icon="el-icon-refresh-right" @click="reset">重置</el-button>
            <el-button type="success" class="addzzh" @click="disAddBusiness()">
                添加商户</el-button>
        </el-form>
        <div class="infoBox">
            <div class="table-data">
                <TableLoading :loadShow="loadShow"></TableLoading>
                <el-table :data="tableData" border>
                    <el-table-column fixed label="序号" width="70">
                        <template slot-scope="{ row }">
                            {{ row.index }}
                            <el-popover placement="top" trigger="hover" v-if="row.isLimit">
                                <div style="text-align: left">
                                    <p>该商家当月已被限制接单</p>
                                </div>
                                <img slot="reference" style="position: absolute;z-index: 999;;left: 40px;" width="25"
                                    src="@/assets/img/business_limit.png" />
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column fixed label="商家名称" width="100">
                        <template slot-scope="{ row }">
                            {{ row.real_name }}
                            <span class="tabSpan" v-show="row.status == 1">新</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="region" label="入驻地区" width="100"></el-table-column>
                    <el-table-column label="所在地址" min-width="300">
                        <template slot-scope="{ row }">
                            <i style="color: #1e9fff; font-size: 18px" class="el-icon-location"></i>
                            {{ row.address }}
                            <el-popover placement="top" trigger="hover">
                                <div style="text-align: left">
                                    <p>经度：{{ row.longitude }}</p>
                                    <p>纬度：{{ row.latitude }}</p>
                                </div>
                                <i slot="reference" class="el-icon-zoom-in"></i>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" width="122">
                        <template slot-scope="{ row }">
                            <StatusTab v-show="row.status == 1" type="red" text="待审核" />
                            <StatusTab v-show="row.status == 2" type="cyan" text="审核通过" />
                            <StatusTab v-show="row.status == 3" type="red" text="审核不通过" :isIcon="true"
                                :hoverText="row.err_msg" />
                            <StatusTab v-show="row.status == 4" type="green" text="营业中" />
                            <StatusTab v-show="row.status == 6" type="green" text="服务中" />
                            <StatusTab v-show="row.status == 5" type="gray" text="休息中" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="isLimit" label="是否共享" width="110" v-if="activeName == 1">
                        <template slot-scope="{ row }">
                            <el-switch v-model="row.isShare" active-color="#13ce66" inactive-color="#ff4949"
                                @change="(status) => changStatus(status, row.business_id)" :active-value="2"
                                :inactive-value="1">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="introduce" label="商家介绍" width="250" />
                    <el-table-column prop="phone" label="联系电话" width="120" v-if="activeName == 1" />
                    <el-table-column label="入驻分类" width="155">
                        <template slot-scope="{ row }">
                            <el-tag class="mt5 ml5" v-for="item in row.type.split(',')" :key="item"
                                size="mini">{{ item }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="本人照片" width="90">
                        <template slot-scope="{ row }">
                            <Img :src="row.self_img" custome_class="miniImg" @click="imgBox(row.self_img)" />
                        </template>
                    </el-table-column>
                    <el-table-column label="身份证照片" width="152" v-if="activeName == 1">
                        <template slot-scope="{ row }">
                            <Img :src="row.sfz_img" custome_class="miniImg" @click="imgBox(row.sfz_img)" />
                            <Img :src="row.sfz_back_img" custome_class="miniImg ml10"
                                @click="imgBox(row.sfz_back_img)" />
                        </template>
                    </el-table-column>
                    <el-table-column label="资格证" width="85">
                        <template slot-scope="{ row }">
                            <Img :src="row.zgz_img" custome_class="miniImg" @click="imgBox(row.zgz_img)" />
                        </template>
                    </el-table-column>
                    <el-table-column label="营业执照" width="85">
                        <template slot-scope="{ row }">
                            <Img :src="row.yyzz_img" custome_class="miniImg" @click="imgBox(row.yyzz_img)" />
                        </template>
                    </el-table-column>
                    <el-table-column label="相册" width="210" class-name="prodown">
                        <template slot-scope="{ row }">
                            <div v-if="row.photos.length && activeName == 1" class="shxj pl10" @click="photosRoot(row)">
                                点击审核</div>
                            <Img :key="index" v-for="item, index in row.photos" :src="item" custome_class="miniImg mr10"
                                @click="imgBox(item)" />
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="120">
                        <template slot-scope="{ row }">
                            <div v-if="activeName == '1'">
                                <el-button type="text" icon="el-icon-edit" @click="openEdit(row)">编辑</el-button>
                                <el-button type="text" icon="el-icon-check" class="ml10" v-show="row.status === 1"
                                    @click="open(row.business_id)">通过</el-button>
                                <el-button type="text" icon="el-icon-close" class="ml10" v-show="row.status === 1"
                                    @click="openRefuse(row.business_id)">拒绝</el-button>
                            </div>
                            <div v-else>
                                <el-button v-if="!row.isBeMyUse" type="success" size="small" icon="el-icon-success"
                                    @click="ChangeUse('2', row.business_id)">使用</el-button>
                                <el-button v-else type="danger" icon="el-icon-error" size="small"
                                    @click="ChangeUse('1', row.business_id)">取消使用</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pageBox">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-sizes="[10]" :page-size="pageSize" :total="total">
                </el-pagination>
            </div>
        </div>

        <!-- 图片查看 -->
        <el-dialog title="图片查看" :visible.sync="centerDialogVisible" style="margin-top: -50px" custom-class="my-dialog"
            center>
            <div style="width: 550px">
                <Img :src="selectImgUrl" alt="" mystyle="width: inherit" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">关闭</el-button>
            </span>
        </el-dialog>
        <!-- 拒绝弹窗 -->
        <el-dialog title="拒绝审核" :visible.sync="refuseVisible" width="400px">
            <el-form>
                <el-form-item label="拒绝原因">
                    <el-input placeholder="请输入拒绝原因" v-model="refuseText" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="refuseVisible = false">取 消</el-button>&nbsp;&nbsp;
                <el-button type="primary" :loading="btnLoading" @click="updateStatus(3)">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 添加商户对话框 -->
        <el-dialog :title="business_id ? '编辑商户' : '添加商户'" :visible.sync="addBusinessDialoge" custom-class="my-dialog"
            :close-on-click-modal="false" width="800px" class="fyxx">
            <div style="padding: 0 30px">
                <div class="addAccountBox">
                    <div class="label-after-div">
                        <label for=""><span>*</span>真实姓名：</label>
                        <el-input v-model="businessData.real_name" placeholder="标题" maxlength="20"
                            autocomplete="off"></el-input>
                    </div>
                    <div class="label-after-div">
                        <label for=""><span>*</span>入驻区域：</label>
                        <el-select v-model="businessData.region" placeholder="请选择">
                            <el-option v-for="item in regionData" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="label-after-div">
                        <label for=""><span>*</span>入驻分类：</label>
                        <el-checkbox-group v-model="checkList">
                            <el-checkbox label="养生SPA" />
                            <el-checkbox label="女性专区" />
                            <el-checkbox label="调理项目" />
                        </el-checkbox-group>
                    </div>
                    <div class="label-after-div">
                        <label for=""><span>*</span>性别：</label>
                        <el-radio-group v-model="businessData.gender">
                            <el-radio :label="1">女</el-radio>
                            <el-radio :label="2">男</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="label-after-div">
                        <label for=""><span>*</span>手机号：</label>
                        <el-input v-model="businessData.phone" placeholder="请输入手机号" maxlength="11" type="number"
                            autocomplete="off"></el-input>
                    </div>
                    <div class="label-after-div">
                        <label for=""><span>*</span>所在地址：</label>
                        <el-input v-model="businessData.address" placeholder="请输入所在地址" maxlength="50"
                            autocomplete="off"></el-input>
                    </div>
                    <div class="label-after-div">
                        <label for=""><span>*</span>工作经验：</label>
                        <el-radio-group v-model="businessData.work">
                            <el-radio :label="1">有</el-radio>
                            <el-radio :label="2">无</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="label-after-div">
                        <label for=""><span>*</span>自我介绍：</label>
                        <el-input maxlength="200" type="textarea" :rows="4" placeholder="请输入自我介绍"
                            v-model="businessData.introduce">
                        </el-input>
                    </div>
                    <div class="flex jus-bet">
                        <div>
                            <label for=""><span class="color-red">*</span>本人照片：</label>
                            <el-upload class="upload-demo mt10" :action="BASE_URL + '/myapp/upload_image'"
                                :show-file-list="false" list-type="picture-card" name="img_file" multiple
                                :on-success="(res) => uploadSuccess(res, 'self_img')"
                                :on-error="(res) => uploadError(res, 'self_img')">
                                <img v-if="businessData.self_img" style="width: 100%; height: 100%"
                                    :src="businessData.self_img" class="avatar" />
                                <el-button v-else icon="el-icon-plus" type="text"></el-button>
                            </el-upload>
                        </div>
                        <div>
                            <label for=""><span class="color-red">*</span>身份证正面：</label>
                            <el-upload class="upload-demo mt10" :action="BASE_URL + '/myapp/upload_image'"
                                :show-file-list="false" list-type="picture-card" name="img_file" multiple
                                :on-success="(res) => uploadSuccess(res, 'sfz_img')"
                                :on-error="(res) => uploadError(res, 'sfz_img')">
                                <img v-if="businessData.sfz_img" style="width: 100%; height: 100%"
                                    :src="businessData.sfz_img" class="avatar" />
                                <el-button v-else icon="el-icon-plus" type="text"></el-button>
                            </el-upload>
                        </div>
                        <div>
                            <label for=""><span class="color-red">*</span>身份证反面：</label>
                            <el-upload class="upload-demo mt10" :action="BASE_URL + '/myapp/upload_image'"
                                :show-file-list="false" list-type="picture-card" name="img_file" multiple
                                :on-success="(res) => uploadSuccess(res, 'sfz_back_img')"
                                :on-error="(res) => uploadError(res, 'sfz_back_img')">
                                <img v-if="businessData.sfz_back_img" style="width: 100%; height: 100%"
                                    :src="businessData.sfz_back_img" class="avatar" />
                                <el-button v-else icon="el-icon-plus" type="text"></el-button>
                            </el-upload>
                        </div>
                        <div>
                            <label for=""><span class="color-red">*</span>资格证：</label>
                            <el-upload class="upload-demo mt10" :action="BASE_URL + '/myapp/upload_image'"
                                :show-file-list="false" list-type="picture-card" name="img_file" multiple
                                :on-success="(res) => uploadSuccess(res, 'zgz_img')"
                                :on-error="(res) => uploadError(res, 'zgz_img')">
                                <img v-if="businessData.zgz_img" style="width: 100%; height: 100%;"
                                    :src="businessData.zgz_img" class="avatar" />
                                <el-button v-else icon="el-icon-plus" type="text"></el-button>
                            </el-upload>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addBusinessDialoge = false">取 消</el-button>&nbsp;&nbsp;&nbsp;
                <el-button type="primary" @click="business_id ? subEditBusiness() : subAddBusiness()"
                    :loading="btnLoading">确
                    定</el-button>
            </div>
        </el-dialog>
        <!-- 照片审核 -->
        <el-dialog title="照片审核" :visible.sync="photosVisible" width="800px">
            <div class="flex pt20">
                <div v-for="item, index in photos" :key="item" class=" mlr20">
                    <el-image style="object-fit: contain;max-height: 300px;max-width: 200px;width: auto !important;"
                        :src="item" :preview-src-list="photos" />
                    <div class="mt10">

                        <el-popconfirm confirm-button-text='确定' cancel-button-text='取消' icon="el-icon-info"
                            @confirm="downPhotos(item, index)" icon-color="red" title="确定要下架当前图片吗？">
                            <el-button slot="reference">下架</el-button>
                        </el-popconfirm>
                    </div>
                </div>
            </div>
            <div class="mtb20"><span style="color: red;">*</span>是否通知消息通知：<template>
                    <el-radio v-model="radio" :label="true">是</el-radio>
                    <el-radio v-model="radio" :label="false">否</el-radio>
                </template></div>
            <el-form label-width="90px">
                <el-form-item label="消息内容：">
                    <el-input :disabled="!radio" placeholder="请输入消息内容" v-model="photosText"
                        autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button :disabled="!radio" type="text" class="mr10" v-for="item, index in photosTexts"
                        :key="item" size="mini" @click="selectPhotosText(index)">{{ item }}</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="photosVisible = false">取 消</el-button>&nbsp;&nbsp;
                <el-button type="primary" :loading="btnLoading" @click="subPhotos">发送消息</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    AdminGetBusinessInfoApi,
    GetBusinessShareInfoApi,
    AdminUpdateBusinessStatusApi,
    AdminSmsNotifyApi,
    AdminAddBusinessApi,
    AdminEditBusinessApi,
    AdminPhotosSendMessageApi,
    AdminDownPhotosApi,
    ChangeIsShareStatusApi,
    ChangeUseInfoApi,
} from "@/common/api";
import TableLoading from "@/components/index/TableLoading.vue";
import StatusTab from "@/components/index/StatusTab.vue";
import Img from "@/components/img/Img.vue";
import { BASE_URL } from "@/common/config";

const defaultBusinessData = {
    address: "",
    brand: "",
    create_time: "",
    err_msg: "",
    gender: "",
    index: "",
    introduce: "",
    latitude: "",
    login_id: "",
    longitude: "",
    phone: "",
    real_name: "",
    reference_id: "",
    region: "",
    self_img: "",
    sfz_back_img: "",
    sfz_img: "",
    status: "",
    type: "",
    work: "",
    zgz_img: "",
};
export default {
    data() {
        return {
            BASE_URL: BASE_URL,
            business_name: "",
            loadShow: false,
            radio: true,
            pageNum: 1, // 当前页数
            total: 0, // 总页数
            pageSize: 10, // 每页显示条数
            tableData: [],
            selectedValue: "",
            refuseText: "",
            photosText: "",
            photos: [],
            photosTexts: [
                '您的相册某些图片存在违规已被强制下架',
                '您的相册图片格式不符合平台规范'
            ],
            business_id: "",
            login_id: "",
            loading: false,
            refuseVisible: false,
            photosVisible: false,
            addBusinessDialoge: false,
            checkList: [],
            businessData: defaultBusinessData,
            businessImgUrl: {},
            regionData: [
                { value: "重庆市", label: "重庆市" },
                { value: "璧山区", label: "璧山区" },
                { value: "常德市", label: "常德市" },
                { value: "涪陵区", label: "涪陵区" },
                { value: "福州市", label: "福州市" },
                { value: "赣州市", label: "赣州市" },
                { value: "洛阳市", label: "洛阳市" },
                { value: "哈尔滨市", label: "哈尔滨市" },
                { value: "南昌市", label: "南昌市" },
                { value: "昆明市", label: "昆明市" },
                { value: "拉萨市", label: "拉萨市" },
                { value: "万州区", label: "万州区" },
                { value: "龙岩市", label: "龙岩市" },
                { value: "温州市", label: "温州市" },
                { value: "泉州市", label: "泉州市" },
                { value: "宜昌市", label: "宜昌市" },
                { value: "厦门市", label: "厦门市" },
                { value: "漳州市", label: "漳州市" },
                { value: "襄阳市", label: "襄阳市" },
                { value: "宜宾市", label: "宜宾市" },
                { value: "长沙市", label: "长沙市" },
                { value: "永川区", label: "永川区" },
                { value: "郑州市", label: "郑州市" },
                { value: "绵阳市", label: "绵阳市" },
                { value: "遵义市", label: "遵义市" },
                { value: "南充市", label: "南充市" },
                { value: "成都市", label: "成都市" },
                { value: "开州区", label: "开州区" },
                { value: "广州市", label: "广州市" },
                { value: "贵阳市", label: "贵阳市" },
                { value: "武汉市", label: "武汉市" },
                { value: "西安市", label: "西安市" },
                { value: "凉山彝族自治州", label: "凉山彝族自治州" },
                { value: "宁波市", label: "宁波市" },
                { value: "合肥市", label: "合肥市" },
                { value: "苏州市", label: "苏州市" },
                { value: "杭州市", label: "杭州市" },
                { value: "南京市", label: "南京市" },
                { value: "济南市", label: "济南市" },
                { value: "青岛市", label: "青岛市" },
                { value: "太原市", label: "太原市" },
                { value: "揭阳市", label: "揭阳市" },
                { value: "常州市", label: "常州市" },
                { value: "株洲市", label: "株洲市" },
                { value: "轻松到家", label: "轻松到家" },
                { value: "攀枝花市", label: "攀枝花市" },
                { value: "汕头市", label: "汕头市" },
                { value: "沈阳市", label: "沈阳市" },
                { value: "无锡市", label: "无锡市" },
                { value: "黔西南布依族苗族自治州", label: "黔西南布依族苗族自治州" },
                { value: "东莞市", label: "东莞市" },
                { value: "驻马店市", label: "驻马店市" },
                { value: "芜湖市", label: "芜湖市" },
                { value: "安顺市", label: "安顺市" },
                { value: "铜仁市", label: "铜仁市" },
                { value: "荆州市", label: "荆州市" },
                { value: "孝感市", label: "孝感市" },
                { value: "合川区", label: "合川区" },
                { value: "六盘水市", label: "六盘水市" },
                { value: "平顶山市", label: "平顶山市" },
                { value: "泸州市", label: "泸州市" },
                { value: "乐山市", label: "乐山市" },
                { value: "自贡市", label: "自贡市" },
                { value: "达州市", label: "达州市" },
                { value: "秦皇岛市", label: "秦皇岛市" },
                { value: "内江市", label: "内江市" },
                { value: "上海市", label: "上海市" },
                { value: "大连市", label: "大连市" },
                { value: "新乡市", label: "新乡市" },
                { value: "毕节市", label: "毕节市" },
                { value: "潍坊市", label: "潍坊市" },
                { value: "阿克苏市", label: "阿克苏市" },
                { value: "德阳市", label: "德阳市" },
                { value: "清镇市", label: "清镇市" },
                { value: "仁怀市", label: "仁怀市" },
                { value: "文山市", label: "文山市" },
                { value: "海口市", label: "海口市" }
            ],
            statusData: [
                {
                    value: 1,
                    label: "待审核",
                },
                {
                    value: 2,
                    label: "审核通过",
                },
                {
                    value: 3,
                    label: "审核不通过",
                },
                {
                    value: 4,
                    label: "营业中",
                },
                {
                    value: 5,
                    label: "休息中",
                },
                {
                    value: 6,
                    label: "服务中",
                },
            ],
            dialogFormVisible: false,
            btnLoading: false,
            priceData: {},
            selectImgUrl: "",
            centerDialogVisible: false,
            activeName: "1",    // 内部/外部商家切换栏
        };
    },
    components: {
        TableLoading,
        StatusTab,
        Img
    },
    async created() {
        // this.getTableData();
    },
    watch: {
        $route: {
            immediate: true, // 立即监听
            handler(val, oldval) {
                if (val.query.type === "10") {
                    this.selectedValue = Number(val.query.status) || this.selectedValue;
                    this.business_name = "";
                }
                this.getTableData(this.pageNum, this.activeName);
            },
        },
        centerDialogVisible: {
            handler(newValue) {
                if (!newValue) {
                    let timer = setTimeout(() => {
                        this.selectImgUrl = "";
                        clearTimeout(timer);
                    }, 300);
                }
            },
        },
    },
    mounted() {
        // 监听回车事件
        document.addEventListener("keyup", this.enterSearch);
    },
    methods: {
        // 切换内部外部导航栏
        handleClick() {
            console.log(this.activeName);
            this.getTableData(1, this.activeName)
        },
        // 商家是否共享
        changStatus(isShare, business_id) {
            this.$confirm(`是否确定${isShare == 2 ? '【共享】商家？' : '【关闭共享】商家？'}`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.loadShow = true
                let res = await ChangeIsShareStatusApi({
                    isShare: isShare,
                    business_id: business_id
                })
                this.loadShow = false
                if (!res) return
                this.getTableData(this.pageNum, this.activeName);
            })
        },
        async ChangeUse(isUse, business_id) {
            this.$confirm(`是否确定${isUse == 2 ? '【使用】外部商家？' : '【取消使用】外部商家？'}`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                this.loadShow = true
                let res = await ChangeUseInfoApi({
                    isUse,
                    business_id
                })
                this.loadShow = false
                if (!res) return
                this.getTableData(this.pageNum, this.activeName)
            })
        },
        async downPhotos(image_name, index) {
            const res = await AdminDownPhotosApi({
                image_name: image_name.split('/').reverse()[0],
                business_id: this.business_id
            })
            if (!res) return
            this.photos.splice(index, 1)
        },
        async subPhotos() {
            if (this.radio) {
                if (this.photosText.trim() === '') {
                    this.$message.warning('请输入消息内容')
                    return
                }
                this.btnLoading = true
                const res = await AdminPhotosSendMessageApi({
                    photosText: this.photosText,
                    accept_id: this.login_id
                })
                this.btnLoading = false
                if (!res) return

                this.getTableData(1, this.activeName)
            }
            this.photos = []
            this.business_id = ''
            this.login_id = ''
            this.photosText = ''
            this.photosVisible = false
        },
        selectPhotosText(index) {
            this.photosText = this.photosTexts[index]
        },
        // 打开照片审核窗口
        photosRoot(row) {
            this.photos = [...row.photos]
            this.business_id = row.business_id
            this.login_id = row.login_id
            this.photosText = ''
            this.photosVisible = true
            this.radio = true
        },
        uploadSuccess(res, key) {
            this.businessData[key] = res.data.image_url;
        },
        uploadError(res, key) {
            this.$message.error("文件上传失败");
        },
        async subEditBusiness() {
            if (this.businessData.address.trim() === "") {
                this.$message.error("请填写所在地址");
                return;
            }
            this.btnLoading = true;
            const res = await AdminEditBusinessApi({
                ...this.businessData,
                self_img: this.businessData.self_img.split("/").pop(),
                sfz_img: this.businessData.sfz_img.split("/").pop(),
                sfz_back_img: this.businessData.sfz_back_img.split("/").pop(),
                zgz_img: this.businessData.zgz_img.split("/").pop(),
                type: this.checkList.join(","),
            });
            this.btnLoading = false;
            if (!res) return;
            this.addBusinessDialoge = false;
            this.businessData = defaultBusinessData;
            this.getTableData(1, this.activeName);
        },
        async subAddBusiness() {
            if (this.businessData.address.trim() === "") {
                this.$message.error("请填写所在地址");
                return;
            }
            this.btnLoading = true;
            const res = await AdminAddBusinessApi({
                ...this.businessData,
                self_img: this.businessData.self_img.split("/").pop(),
                sfz_img: this.businessData.sfz_img.split("/").pop(),
                sfz_back_img: this.businessData.sfz_back_img.split("/").pop(),
                zgz_img: this.businessData.zgz_img.split("/").pop(),
                type: this.checkList.join(","),
            });
            this.btnLoading = false;
            if (!res) return;
            this.addBusinessDialoge = false;
            this.businessData = defaultBusinessData;
            this.getTableData(1, this.activeName);
        },
        openEdit(row) {
            console.log(row);
            this.business_id = row.business_id;
            this.businessData = { ...row };
            this.checkList = row.type.split(",");
            this.addBusinessDialoge = true;
        },
        disAddBusiness() {
            this.businessData = defaultBusinessData;
            this.addBusinessDialoge = true;
            this.business_id = "";
        },
        // 图片显示框
        imgBox(imgUrl) {
            const img = Array.isArray(imgUrl) ? imgUrl[0] : imgUrl;
            this.selectImgUrl = img;
            this.centerDialogVisible = true;
        },
        // 重置
        reset() {
            this.business_name = "";
            this.selectedValue = "";
            this.pageNum = 1;
            this.getTableData(1, this.activeName);
        },
        // 打开拒绝弹窗
        openRefuse(business_id) {
            this.refuseText = "";
            this.refuseVisible = true;
            this.business_id = business_id;
        },

        // 提示框
        open(id) {
            this.$messageBox
                .confirm(
                    `是否通过该商家的审核? 审核通过后该商家可进行正常经营买卖。`,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "success",
                    }
                )
                .then(
                    () => {
                        this.business_id = id;
                        this.updateStatus(2);
                    },
                    () => { }
                );
        },

        // 修改商家状态
        async updateStatus(status) {
            this.btnLoading = true;
            let res = await AdminUpdateBusinessStatusApi({
                business_id: this.business_id,
                refuseText: this.refuseText,
                status,
            });
            this.btnLoading = false;
            if (!res) return;
            this.refuseText = "";
            this.refuseVisible = false;
            this.business_id = "";
            res.data?.phone &&
                this.$messageBox
                    .confirm(
                        `
          商家审核成功！
          当前创建出商家账号：<br/>
          账号：${res.data.phone}<br/>
          <span style="color:#444;font-size: 18px;display:inline-block;margin-top:10px;">是否短信通知商家？</span>
          `,
                        "提示",
                        {
                            confirmButtonText: "通知",
                            cancelButtonText: "不通知",
                            dangerouslyUseHTMLString: true,
                            type: "success",
                        }
                    )
                    .then(
                        async () => {
                            await AdminSmsNotifyApi({ login_id: res.data.login_id });
                        },
                        () => { }
                    );
            this.getTableData(this.pageNum, this.activeName);
        },
        // 每页条数改变时
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val;
        },
        // 当前页改变时
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getTableData(this.pageNum, this.activeName);
        },
        // 回车键搜索
        enterSearch(event) {
            if (event.keyCode === 13) {
                let isfocus1 =
                    document.activeElement === this.$refs.searchInput1.$refs.input;
                // 判断光标是否聚焦在输入框中
                if (isfocus1) {
                    this.getTableData(1, this.activeName1);
                }
            }
        },
        // 获取表格数据
        async getTableData(page, type) {
            if (page) this.pageNum = page;
            this.loadShow = true;
            let tableData
            if (type == 1) {
                tableData = await AdminGetBusinessInfoApi({
                    business_name: this.business_name.trim(),
                    status: this.selectedValue,
                    page: this.pageNum,
                });
            } else {
                tableData = await GetBusinessShareInfoApi({
                    business_name: this.business_name.trim(),
                    page: this.pageNum,
                });
            }
            this.loadShow = false
            if (!tableData) {
                this.tableData = []
                this.total = 0
                return
            };
            tableData.data.forEach((element, index) => {
                element.index = index + 1 + (this.pageNum - 1) * 10;
            });
            this.tableData = tableData.data;
            this.total = tableData.total;
        },
    },
    beforeDestroy() {
        document.removeEventListener("keyup", this.enterSearch);
    },
};
</script>

<style lang="less" scoped>
.shxj {
    width: 100%;
    position: absolute;
    height: 20px;
    line-height: 20px;
    background-color: #1e9fff;
    color: #fff;
    bottom: -25px;
    left: 0;
    cursor: pointer;
}

.miniImg {
    width: 50px;
    height: 50px;
    object-fit: contain;
}


// @keyframes light {
// 	50%{
// 		filter: hue-rotate(340deg) blur(0.1px);
// 		text-shadow:0 0 5px red;
// 	}
// }

.priceBtn {
    transform: translateY(-10px) !important;
    position: absolute !important;
    z-index: 10 !important;

    button {
        padding: 6px 15px;
        color: white;
        background-color: #1e9fff;
    }
}
</style>
