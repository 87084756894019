<template>
  <span
   class="spanClass"
    :class="
      type == 'pink'
        ? 'ant-tag-pink'
        : type == 'cyan'
        ? 'ant-tag-cyan'
        : type == 'green'
        ? 'ant-tag-green'
        : type == 'red'
        ? 'ant-tag-red'
        : type == 'gray'
        ? 'ant-tag-gray'
        : type == 'orange'
        ? 'ant-tag-orange'
        : ''
    "
    >{{ text }}
    <el-popover v-if="isIcon" placement="top" trigger="hover">
      <div style="text-align: left;">
        <p style="color: red;">{{hoverText}}</p>
      </div>
      <i slot="reference" style="color: red;font-size: 14px;" class="el-icon-warning-outline"></i>
    </el-popover>
    </span
  >
</template>

<script>
export default {
  props: ["type", "text", 'isIcon', 'hoverText'],
};
</script>

<style lang="less" scoped>
.spanClass {
  padding: 2px 6px;
  display: inline;
  border: 1px solid;
  font-size: 12px;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-gray {
  color: #999;
  background: #f6f6f6;
  border-color: #ccc;
}

.ant-tag-orange {
  color: #f1a009;
  background: #fff6d7;
  border-color: #ffb85a;
}
</style>
