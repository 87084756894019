var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"spanClass",class:_vm.type == 'pink'
      ? 'ant-tag-pink'
      : _vm.type == 'cyan'
      ? 'ant-tag-cyan'
      : _vm.type == 'green'
      ? 'ant-tag-green'
      : _vm.type == 'red'
      ? 'ant-tag-red'
      : _vm.type == 'gray'
      ? 'ant-tag-gray'
      : _vm.type == 'orange'
      ? 'ant-tag-orange'
      : ''},[_vm._v(_vm._s(_vm.text)+" "),(_vm.isIcon)?_c('el-popover',{attrs:{"placement":"top","trigger":"hover"}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.hoverText))])]),_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"color":"red","font-size":"14px"},attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }